import { ESTATS_TYPE, sendMetricRequest } from '@pepita/estats';

import { estatsMetrics } from 'src/utils/estats';

export const trackSendModalAction = (
  action: 'disabled' | 'locked' | 'opened' | 'closed'
) => {
  const metricUrl = estatsMetrics[`services.alert_email.auto_popup.${action}`];

  if (metricUrl) {
    sendMetricRequest(metricUrl, ESTATS_TYPE.singleCounter, 1);
  }
};
